import React from 'react';

const ContentSection = () => {
    return (
        <>
            <section className="capabilities__area p-relative grey-bg-12 pt-100 pb-100 fix">
                <div
                    className="capabilities__thumb p-absolute"
                    style={{ background: `url(assets/img/about-bg1.jpg)`, backgroundPosition: 'center', backgroundSize: 'cover' }}>
                </div>
                <div className="container">
                    <div className="row">
                        <div className="col-xl-5">
                            <div className="capabilities__content">
                                <div className="section__title section__title-3 mb-25">
                                    <h2>Revolutionizing Vehicle Management: A Deep Dive into Comprehensive Solutions</h2>
                                </div>
                                <div className="capabilities__list">
                                    <p>In the dynamic landscape of vehicle management, modern platforms have emerged to offer integrated solutions, transforming the way businesses handle their fleets. This article explores the intricacies and benefits of a holistic Vehicle Management Platform, comprising four key components: the Vehicle Rental Management System, Fleet Management System, Garage Management System, and Vehicle and Spare Part Buy/Sell Platform.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="blog__area pt-100 pb-100">
                <div className="container">
                    <div className="row">
                        <div className="col-xl-12">
                            <div className="blog__details-wrapper">
                                <div className="blog__text">
                                    <h4>Vehicle Rental Management System: Streamlining Rental Operations</h4>
                                    <p>The <span>Vehicle Rental Management System</span> is a pivotal component of the platform, designed to optimize and simplify the entire vehicle rental process. From reservation handling to booking management, this system provides businesses with the tools needed to enhance efficiency and customer satisfaction. We'll delve into the features that make this system a game-changer, exploring its impact on reservation processes, customer experiences, and overall operational fluidity.</p>
                                    <h4>Fleet Management System: Maximizing Fleet Efficiency</h4>
                                    <p>Efficient fleet management is crucial for businesses relying on vehicles for their operations. The <span>Fleet Management System</span> within the platform offers a suite of features, including real-time vehicle tracking, maintenance scheduling, and performance analytics. This section will explore how this system empowers businesses to monitor and optimize their fleets, leading to improved logistics, cost-effectiveness, and overall operational excellence.</p>
                                    <h4>Garage Management System: Orchestrating Maintenance Operations</h4>
                                    <p>The <span>Garage Management System</span> is a vital component that focuses on organizing and optimizing garage operations. From maintenance schedules to repair history tracking and spare part inventory management, this system plays a pivotal role in ensuring that vehicles are in optimal condition. We'll delve into the functionalities that make the <span>Garage Management System</span> indispensable for businesses seeking to streamline their maintenance operations and reduce downtime.</p>
                                    <h4>Vehicle and Spare Part Buy/Sell Platform: Creating a Seamless Marketplace</h4>
                                    <p>Facilitating the buying and selling of vehicles and spare parts is made efficient through the <span>Vehicle and Spare Part Buy/Sell Platform</span>. This section will explore how this platform transforms into a secure and convenient marketplace, fostering transactions between buyers and sellers seamlessly. We'll discuss the key features that ensure secure transactions, user-friendly interfaces, and the overall impact on the automotive marketplace.</p>
                                    <h4>Conclusion: The Synergy of Comprehensive Vehicle Management</h4>
                                    <p className="mb-0">In conclusion, the <span>Vehicle Management Platform</span>, comprising the Vehicle Rental Management System, Fleet Management System, Garage Management System, and Vehicle and Spare Part Buy/Sell Platform, represents a paradigm shift in how businesses approach vehicle management. This article aims to provide a thorough understanding of each component's functionalities and the synergies that result in a comprehensive solution. Businesses embracing these innovative platforms stand to gain not only in operational efficiency but also in creating a more sustainable and customer-centric approach to vehicle management in the modern era.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div >
            </section >
        </>
    );
};

export default ContentSection;