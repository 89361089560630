import React from 'react';
import { FcApproval } from "react-icons/fc";

const ContentSection = () => {
    const scrollTo = (id) => {
        const element = document.getElementById(id);
        element.scrollIntoView({
            behavior: "smooth",
        });
    };
    return (
        <>
            <section className="achievement__area sticky">
                <div className="container">
                    <div className="row">
                        <div className="col-xl-12">
                            <div className="scrollLinks">
                                <button onClick={() => scrollTo("enduser")}>End User Features</button>
                                <button onClick={() => scrollTo("admin")}>Admin Side Features</button>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="achievement__area pt-100 pb-100 fixBox" id="enduser">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-xl-12">
                            <div className="section__title section__title-4 text-center mb-55">
                                <h2>End User Features of Vehicle Rental Management System</h2>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-xl-12">
                            <div className="achievement__wrapper">
                                <div className="achievement__item">
                                    <i><FcApproval /></i>
                                    <h3>User Registration & Profile</h3>
                                    <ul>
                                        <li><span><b>User Account Creation:</b> Easily create a user account with personal details and preferences.</span></li>
                                        <li><span><b>Profile Management:</b> Update contact information, upload identification documents, and manage preferences.</span></li>
                                    </ul>
                                </div>
                                <div className="achievement__item">
                                    <i><FcApproval /></i>
                                    <h3>Vehicle Search & Listings</h3>
                                    <ul>
                                        <li><span><b>Advanced Search Filters:</b> Refine vehicle search based on criteria such as type, model, price, and location.</span></li>
                                        <li><span><b>Detailed Vehicle Listings:</b> Access comprehensive information about each vehicle, including images and specifications.</span></li>
                                    </ul>
                                </div>
                                <div className="achievement__item">
                                    <i><FcApproval /></i>
                                    <h3>Booking & Reservation</h3>
                                    <ul>
                                        <li><span><b>Online Booking:</b> Securely book vehicles online through an intuitive booking system.</span></li>
                                        <li><span><b>Reservation Confirmation:</b> Receive immediate confirmation and details of booked reservations.</span></li>
                                    </ul>
                                </div>
                                <div className="achievement__item">
                                    <i><FcApproval /></i>
                                    <h3>Calendar & Availability</h3>
                                    <ul>
                                        <li><span><b>Real-Time Availability:</b> View real-time availability calendars for each vehicle.</span></li>
                                        <li><span><b>Date Selection:</b> Choose and select desired rental dates with ease.</span></li>
                                    </ul>
                                </div>
                                <div className="achievement__item">
                                    <i><FcApproval /></i>
                                    <h3>Vehicle Customization</h3>
                                    <ul>
                                        <li><span><b>Additional Services:</b> Add extra services such as GPS, child seats, or insurance during the booking process.</span></li>
                                        <li><span><b>Special Requests:</b> Include any special requests or preferences related to the rental.</span></li>
                                    </ul>
                                </div>
                                <div className="achievement__item">
                                    <i><FcApproval /></i>
                                    <h3>Payment Integration</h3>
                                    <ul>
                                        <li><span><b>Secure Online Payments:</b> Make secure online payments for bookings through integrated payment gateways.</span></li>
                                        <li><span><b>Digital Receipts:</b> Receive digital receipts for all transactions.</span></li>
                                    </ul>
                                </div>
                                <div className="achievement__item">
                                    <i><FcApproval /></i>
                                    <h3>Communication Tools</h3>
                                    <ul>
                                        <li><span><b>Messaging System:</b> Communicate with the rental provider through an integrated messaging system.</span></li>
                                        <li><span><b>Notification Center:</b> Receive alerts and notifications related to bookings and updates.</span></li>
                                    </ul>
                                </div>
                                <div className="achievement__item">
                                    <i><FcApproval /></i>
                                    <h3>Review & Rating System</h3>
                                    <ul>
                                        <li><span><b>Vehicle Reviews:</b> Share and read reviews from other users about specific vehicles.</span></li>
                                        <li><span><b>Rating System:</b> Provide ratings based on the overall vehicle rental experience.</span></li>
                                    </ul>
                                </div>
                                <div className="achievement__item">
                                    <i><FcApproval /></i>
                                    <h3>Document Management</h3>
                                    <ul>
                                        <li><span><b>Digital Contracts:</b> Access and sign digital rental agreements and contracts.</span></li>
                                        <li><span><b>License Verification:</b> Upload and verify driving license details for authentication.</span></li>
                                    </ul>
                                </div>
                                <div className="achievement__item">
                                    <i><FcApproval /></i>
                                    <h3>User Support & Assistance</h3>
                                    <ul>
                                        <li><span><b>Help Center:</b> Access a help center or knowledge base for assistance and FAQs.</span></li>
                                        <li><span><b>Customer Support Integration:</b> Contact customer support for inquiries or assistance.</span></li>
                                    </ul>
                                </div>
                                <div className="achievement__item">
                                    <i><FcApproval /></i>
                                    <h3>Cancellation & Refund Policies</h3>
                                    <ul>
                                        <li><span><b>Clear Policies:</b> Understand and adhere to transparent cancellation and refund policies.</span></li>
                                        <li><span><b>Refund Status:</b> Track the status of refund requests, if applicable.</span></li>
                                    </ul>
                                </div>
                                <div className="achievement__item">
                                    <i><FcApproval /></i>
                                    <h3>Profile Dashboard</h3>
                                    <ul>
                                        <li><span><b>Dashboard Overview:</b> Get an overview of past and upcoming vehicle rentals on the user dashboard.</span></li>
                                        <li><span><b>Rental History:</b> Access a detailed history of previous vehicle rentals.</span></li>
                                    </ul>
                                </div>
                                <div className="achievement__item">
                                    <i><FcApproval /></i>
                                    <h3>Loyalty Programs</h3>
                                    <ul>
                                        <li><span><b>Reward Points:</b> Participate in loyalty programs and earn reward points for repeat vehicle rentals.</span></li>
                                        <li><span><b>Discounts & Offers:</b> Receive exclusive discounts and offers for loyal users.</span></li>
                                    </ul>
                                </div>
                                <div className="achievement__item">
                                    <i><FcApproval /></i>
                                    <h3>Feedback & Improvement</h3>
                                    <ul>
                                        <li><span><b>Feedback Forms:</b> Provide feedback on the vehicle rental experience for continuous improvement.</span></li>
                                        <li><span><b>Suggestions:</b> Submit suggestions for new features or improvements.</span></li>
                                    </ul>
                                </div>
                                <div className="achievement__item">
                                    <i><FcApproval /></i>
                                    <h3>Mobile Accessibility</h3>
                                    <ul>
                                        <li><span><b>Mobile App Access:</b> Conveniently access the vehicle rental management system through a dedicated mobile application.</span></li>
                                        <li><span><b>Mobile Alerts:</b> Receive push notifications for booking updates and reminders.</span></li>
                                    </ul>
                                </div>
                                <div className="achievement__item">
                                    <i><FcApproval /></i>
                                    <h3>Community Forums</h3>
                                    <ul>
                                        <li><span><b>User Discussions:</b> Engage in discussions with other users about vehicle rental experiences and recommendations.</span></li>
                                        <li><span><b>Local Insights:</b> Share insights about local routes, parking, and travel tips.</span></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="achievement__area pt-100 pb-100 fixBox grey-bg-12" id="admin">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-xl-12">
                            <div className="section__title section__title-4 text-center mb-55">
                                <h2>Admin Side Features of Vehicle Rental Management System</h2>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-xl-12">
                            <div className="achievement__wrapper">
                                <div className="achievement__item">
                                    <i><FcApproval /></i>
                                    <h3>Dashboard & Overview</h3>
                                    <ul>
                                        <li><span><b>Comprehensive Dashboard:</b> Access a centralized dashboard for real-time insights into the overall rental operation.</span></li>
                                        <li><span><b>Quick Stats:</b> View key metrics, including total bookings, available vehicles, and revenue.</span></li>
                                    </ul>
                                </div>
                                <div className="achievement__item">
                                    <i><FcApproval /></i>
                                    <h3>Vehicle Management</h3>
                                    <ul>
                                        <li><span><b>Vehicle Catalog:</b> Manage and update the catalog of available vehicles with detailed specifications.</span></li>
                                        <li><span><b>Add/Remove Vehicles:</b> Easily add new vehicles to the fleet or retire old ones from the rental pool.</span></li>
                                    </ul>
                                </div>
                                <div className="achievement__item">
                                    <i><FcApproval /></i>
                                    <h3>Booking Management</h3>
                                    <ul>
                                        <li><span><b>Reservation Overview:</b> Monitor real-time reservations and upcoming bookings.</span></li>
                                        <li><span><b>Booking Modification:</b> Allow modifications to bookings, such as date changes or additional services.</span></li>
                                    </ul>
                                </div>
                                <div className="achievement__item">
                                    <i><FcApproval /></i>
                                    <h3>User Management</h3>
                                    <ul>
                                        <li><span><b>User Profiles:</b> Access and manage user profiles, including verification of identification documents.</span></li>
                                        <li><span><b>User Activity Logs:</b> Keep track of user interactions, bookings, and communication.</span></li>
                                    </ul>
                                </div>
                                <div className="achievement__item">
                                    <i><FcApproval /></i>
                                    <h3>Calendar & Availability</h3>
                                    <ul>
                                        <li><span><b>Real-Time Availability:</b> Monitor the availability of each vehicle on a centralized calendar.</span></li>
                                        <li><span><b>Date Range Reports:</b> Generate reports on vehicle availability over specified date ranges.</span></li>
                                    </ul>
                                </div>
                                <div className="achievement__item">
                                    <i><FcApproval /></i>
                                    <h3>Pricing & Billing Management</h3>
                                    <ul>
                                        <li><span><b>Dynamic Pricing:</b> Implement dynamic pricing strategies based on demand and availability.</span></li>
                                        <li><span><b>Invoice Generation:</b> Automatically generate invoices for completed bookings.</span></li>
                                    </ul>
                                </div>
                                <div className="achievement__item">
                                    <i><FcApproval /></i>
                                    <h3>Communication Tools</h3>
                                    <ul>
                                        <li><span><b>Messaging Interface:</b> Communicate with users and respond to inquiries through an integrated messaging system.</span></li>
                                        <li><span><b>Notification Management:</b> Send important notifications to users regarding bookings, changes, or promotions.</span></li>
                                    </ul>
                                </div>
                                <div className="achievement__item">
                                    <i><FcApproval /></i>
                                    <h3>Document Verification</h3>
                                    <ul>
                                        <li><span><b>License Verification:</b> Verify and validate driving licenses submitted by users.</span></li>
                                        <li><span><b>Insurance & Registration Checks:</b> Ensure that vehicles have up-to-date insurance and registration documentation.</span></li>
                                    </ul>
                                </div>
                                <div className="achievement__item">
                                    <i><FcApproval /></i>
                                    <h3>Payment Management</h3>
                                    <ul>
                                        <li><span><b>Transaction Monitoring:</b> Monitor all payment transactions for accuracy and security.</span></li>
                                        <li><span><b>Refund Processing:</b> Process refunds efficiently in accordance with the refund policies.</span></li>
                                    </ul>
                                </div>
                                <div className="achievement__item">
                                    <i><FcApproval /></i>
                                    <h3>Review & Rating Management</h3>
                                    <ul>
                                        <li><span><b>Review Moderation:</b> Moderate and manage user reviews to ensure authenticity and adherence to guidelines.</span></li>
                                        <li><span><b>Rating Analysis:</b> Analyze overall ratings and feedback for continuous improvement.</span></li>
                                    </ul>
                                </div>
                                <div className="achievement__item">
                                    <i><FcApproval /></i>
                                    <h3>Reporting & Analytics</h3>
                                    <ul>
                                        <li><span><b>Booking Reports:</b> Generate reports on booking trends, revenue, and user demographics.</span></li>
                                        <li><span><b>Vehicle Performance:</b> Analyze the performance of individual vehicles, including utilization rates.</span></li>
                                    </ul>
                                </div>
                                <div className="achievement__item">
                                    <i><FcApproval /></i>
                                    <h3>Security & Access Control</h3>
                                    <ul>
                                        <li><span><b>Role-Based Access:</b> Implement role-based access controls for different admin users.</span></li>
                                        <li><span><b>Data Security Measures:</b> Ensure the security of user and transaction data.</span></li>
                                    </ul>
                                </div>
                                <div className="achievement__item">
                                    <i><FcApproval /></i>
                                    <h3>Maintenance Management</h3>
                                    <ul>
                                        <li><span><b>Scheduled Maintenance:</b> Schedule regular maintenance for vehicles to ensure optimal performance.</span></li>
                                        <li><span><b>Repair Logs:</b> Maintain logs of repairs and maintenance activities for each vehicle.</span></li>
                                    </ul>
                                </div>
                                <div className="achievement__item">
                                    <i><FcApproval /></i>
                                    <h3>Inventory Management</h3>
                                    <ul>
                                        <li><span><b>Spare Parts Inventory:</b> Manage an inventory of spare parts for vehicle repairs.</span></li>
                                        <li><span><b>Restocking Alerts:</b> Receive alerts for low-stock spare parts.</span></li>
                                    </ul>
                                </div>
                                <div className="achievement__item">
                                    <i><FcApproval /></i>
                                    <h3>Customer Support Integration</h3>
                                    <ul>
                                        <li><span><b>Support Ticket System:</b> Integrate a support ticket system for efficient query resolution.</span></li>
                                        <li><span><b>FAQ Management:</b> Manage and update a database of frequently asked questions.</span></li>
                                    </ul>
                                </div>
                                <div className="achievement__item">
                                    <i><FcApproval /></i>
                                    <h3>Integration Capabilities</h3>
                                    <ul>
                                        <li><span><b>Third-Party Integrations:</b> Integrate with external services, such as payment gateways or GPS tracking systems.</span></li>
                                        <li><span><b>API Access:</b> Provide API access for seamless integration with other enterprise systems.</span></li>
                                    </ul>
                                </div>
                                <div className="achievement__item">
                                    <i><FcApproval /></i>
                                    <h3>Customization Options</h3>
                                    <ul>
                                        <li><span><b>Settings Configuration:</b> Customize system settings, including rental terms, cancellation policies, and notification preferences.</span></li>
                                        <li><span><b>Branding Elements:</b> Implement branding elements for a personalized user experience.</span></li>
                                    </ul>
                                </div>
                                <div className="achievement__item">
                                    <i><FcApproval /></i>
                                    <h3>Data Backup & Recovery</h3>
                                    <ul>
                                        <li><span><b>Regular Backups:</b> Implement regular data backups to prevent data loss.</span></li>
                                        <li><span><b>Recovery Procedures:</b> Have recovery procedures in place in case of system failures.</span></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="services__area pt-100 pb-100">
                <div className="container">
                    <div className="row">
                        <div className="col-xl-12">
                            <div className="section__title section__title-4 text-center mb-55">
                                <h2>Available Platforms</h2>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-xl-4">
                            <div className="services__item mb-15 mb-lg-0">
                                <div className="services__icon mb-20">
                                    <img src="assets/img/platform-icon1.jpg" alt="Sjain" />
                                </div>
                                <div className="services__content">
                                    <h3>Web Panel for End User</h3>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-4">
                            <div className="services__item mb-15 mb-lg-0">
                                <div className="services__icon mb-20">
                                    <img src="assets/img/platform-icon2.jpg" alt="Sjain" />
                                </div>
                                <div className="services__content">
                                    <h3>Mobile App for End User</h3>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-4">
                            <div className="services__item">
                                <div className="services__icon mb-20">
                                    <img src="assets/img/platform-icon4.jpg" alt="Sjain" />
                                </div>
                                <div className="services__content">
                                    <h3>Web Panel for Admin</h3>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default ContentSection;