import React from 'react';
import { Link } from 'react-router-dom';

const HeroSection = () => {
    return (
        <>
            <section className="slider__area">
                <div className="single-slider slider__height d-flex align-items-center" style={{
                    background: `url(assets/img/home-slide1.jpg)`, backgroundPosition: 'center', backgroundSize: 'cover'
                }}>
                    <div className="container-fluid">
                        <div className="row align-items-end">
                            <div className="col-xl-5 offset-xl-1">
                                <div className="slider__content text-center text-lg-start mb-40">
                                    <h1>Navigate Future with Perfect Vehicle Management System</h1>
                                    <p>Beyond Wheels: Smarter Management Solutions</p>
                                    <div className="slider__btn">
                                        <Link to="/contact" className="z-btn">Request for Free Demo</Link>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-6">
                                <div className="slider__content m-img r-img">
                                    <img src="assets/img/home-slide2.jpg" alt="Sjain" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default HeroSection;