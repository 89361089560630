import React from 'react';
import { FcApproval } from "react-icons/fc";

const ContentSection = () => {
    const scrollTo = (id) => {
        const element = document.getElementById(id);
        element.scrollIntoView({
            behavior: "smooth",
        });
    };
    return (
        <>
            <section className="achievement__area sticky">
                <div className="container">
                    <div className="row">
                        <div className="col-xl-12">
                            <div className="scrollLinks">
                                <button onClick={() => scrollTo("enduser")}>End User Side Features</button>
                                <button onClick={() => scrollTo("admin")}>Admin Side Features</button>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="achievement__area pt-100 pb-100 fixBox" id="enduser">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-xl-12">
                            <div className="section__title section__title-4 text-center mb-55">
                                <h2>End User Side Features of Vehicle Spare Part & Automobile Buy/Sell Platform</h2>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-xl-12">
                            <div className="achievement__wrapper">
                                <div className="achievement__item">
                                    <i><FcApproval /></i>
                                    <h3>User Registration & Profile</h3>
                                    <ul>
                                        <li><span><b>Account Creation:</b> Easily create a user account with personal details.</span></li>
                                        <li><span><b>Profile Management:</b> Update contact information and preferences.</span></li>
                                    </ul>
                                </div>
                                <div className="achievement__item">
                                    <i><FcApproval /></i>
                                    <h3>Vehicle Browse & Search</h3>
                                    <ul>
                                        <li><span><b>Comprehensive Listings:</b> Access a diverse catalog of spare parts and vehicles for sale.</span></li>
                                        <li><span><b>Advanced Search Filters:</b> Utilize filters for make, model, year, and price range for efficient browsing.</span></li>
                                    </ul>
                                </div>
                                <div className="achievement__item">
                                    <i><FcApproval /></i>
                                    <h3>Product Details</h3>
                                    <ul>
                                        <li><span><b>Detailed Product Descriptions:</b> View comprehensive details about spare parts and vehicles.</span></li>
                                        <li><span><b>High-Quality Images:</b> Visualize products through high-resolution images.</span></li>
                                    </ul>
                                </div>
                                <div className="achievement__item">
                                    <i><FcApproval /></i>
                                    <h3>Compare & Wishlist</h3>
                                    <ul>
                                        <li><span><b>Product Comparison:</b> Compare multiple spare parts or vehicles side by side.</span></li>
                                        <li><span><b>Wishlist Feature:</b> Save favorite items for future reference.</span></li>
                                    </ul>
                                </div>
                                <div className="achievement__item">
                                    <i><FcApproval /></i>
                                    <h3>Seller Ratings & Reviews</h3>
                                    <ul>
                                        <li><span><b>Seller Ratings:</b> Access ratings and reviews for sellers to gauge reliability.</span></li>
                                        <li><span><b>Product Reviews:</b> Read reviews from other users about specific spare parts or vehicles.</span></li>
                                    </ul>
                                </div>
                                <div className="achievement__item">
                                    <i><FcApproval /></i>
                                    <h3>Contact Seller & Negotiation</h3>
                                    <ul>
                                        <li><span><b>Direct Messaging:</b> Communicate directly with sellers through an integrated messaging system.</span></li>
                                        <li><span><b>Negotiation Options:</b> Initiate price negotiations or inquire about additional details.</span></li>
                                    </ul>
                                </div>
                                <div className="achievement__item">
                                    <i><FcApproval /></i>
                                    <h3>Secure Transactions</h3>
                                    <ul>
                                        <li><span><b>Online Payment:</b> Make secure online payments for purchased spare parts or vehicles.</span></li>
                                        <li><span><b>Transaction Confirmation:</b> Receive confirmation and details of completed transactions.</span></li>
                                    </ul>
                                </div>
                                <div className="achievement__item">
                                    <i><FcApproval /></i>
                                    <h3>Seller Verification</h3>
                                    <ul>
                                        <li><span><b>Verification Badges:</b> Identify sellers with verified profiles for added trust.</span></li>
                                        <li><span><b>Document Verification:</b> Ensure sellers provide accurate documentation for vehicles.</span></li>
                                    </ul>
                                </div>
                                <div className="achievement__item">
                                    <i><FcApproval /></i>
                                    <h3>Auction & Bidding (Optional)</h3>
                                    <ul>
                                        <li><span><b>Participation in Auctions:</b> Join online auctions for specific spare parts or vehicles.</span></li>
                                        <li><span><b>Real-Time Bidding:</b> Engage in real-time bidding for desired items.</span></li>
                                    </ul>
                                </div>
                                <div className="achievement__item">
                                    <i><FcApproval /></i>
                                    <h3>Geo-Location & Vehicle Inspection</h3>
                                    <ul>
                                        <li><span><b>Location-Based Search:</b> Find spare parts or vehicles available in a specific geographic location.</span></li>
                                        <li><span><b>Vehicle Inspection Requests:</b> Request vehicle inspections for used cars before purchase.</span></li>
                                    </ul>
                                </div>
                                <div className="achievement__item">
                                    <i><FcApproval /></i>
                                    <h3>Transparent Pricing</h3>
                                    <ul>
                                        <li><span><b>Clear Pricing Information:</b> Access transparent pricing details for spare parts and vehicles.</span></li>
                                        <li><span><b>Additional Costs:</b> Be informed about any additional costs, such as taxes or transfer fees.</span></li>
                                    </ul>
                                </div>
                                <div className="achievement__item">
                                    <i><FcApproval /></i>
                                    <h3>Mobile Accessibility</h3>
                                    <ul>
                                        <li><span><b>Mobile App Access:</b> Conveniently access the platform through a dedicated mobile application.</span></li>
                                        <li><span><b>Push Notifications:</b> Receive alerts for new listings, messages, or auction updates.</span></li>
                                    </ul>
                                </div>
                                <div className="achievement__item">
                                    <i><FcApproval /></i>
                                    <h3>Personalized Recommendations</h3>
                                    <ul>
                                        <li><span><b>AI-driven Suggestions:</b> Receive personalized recommendations based on user preferences.</span></li>
                                        <li><span><b>Featured Listings:</b> Explore specially featured or recommended items.</span></li>
                                    </ul>
                                </div>
                                <div className="achievement__item">
                                    <i><FcApproval /></i>
                                    <h3>Post-Purchase Support</h3>
                                    <ul>
                                        <li><span><b>Delivery Tracking:</b> Track the delivery status of purchased spare parts or vehicles.</span></li>
                                        <li><span><b>Post-Purchase Assistance:</b> Access support for any post-purchase inquiries or issues.</span></li>
                                    </ul>
                                </div>
                                <div className="achievement__item">
                                    <i><FcApproval /></i>
                                    <h3>Feedback & Ratings</h3>
                                    <ul>
                                        <li><span><b>Provide Seller Feedback:</b> Share feedback on the quality of spare parts or vehicles.</span></li>
                                        <li><span><b>Rating System:</b> Rate the overall experience with sellers.</span></li>
                                    </ul>
                                </div>
                                <div className="achievement__item">
                                    <i><FcApproval /></i>
                                    <h3>User Guides & Resources</h3>
                                    <ul>
                                        <li><span><b>Guides & Tutorials:</b> Access guides on vehicle maintenance or spare part installation.</span></li>
                                        <li><span><b>Tips & Recommendations:</b> Receive tips for buying or selling vehicles and spare parts.</span></li>
                                    </ul>
                                </div>
                                <div className="achievement__item">
                                    <i><FcApproval /></i>
                                    <h3>Community Forums</h3>
                                    <ul>
                                        <li><span><b>User Discussions:</b> Engage in discussions with other users about their experiences.</span></li>
                                        <li><span><b>Local Insights:</b> Share insights about local automotive services and experiences.</span></li>
                                    </ul>
                                </div>
                                <div className="achievement__item">
                                    <i><FcApproval /></i>
                                    <h3>Membership Benefits (Optional)</h3>
                                    <ul>
                                        <li><span><b>Participate in Membership Programs:</b> Join membership programs for additional benefits.</span></li>
                                        <li><span><b>Exclusive Offers:</b> Receive exclusive offers and discounts for loyal customers.</span></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="achievement__area pt-100 pb-100 fixBox grey-bg-12" id="admin">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-xl-12">
                            <div className="section__title section__title-4 text-center mb-55">
                                <h2>Admin Side Features of Vehicle Spare Part & Automobile Buy/Sell Platform</h2>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-xl-12">
                            <div className="achievement__wrapper">
                                <div className="achievement__item">
                                    <i><FcApproval /></i>
                                    <h3>Dashboard & Overview</h3>
                                    <ul>
                                        <li><span><b>Comprehensive Dashboard:</b> Access a centralized dashboard displaying real-time insights into platform operations.</span></li>
                                        <li><span><b>Key Metrics:</b> Monitor key metrics such as the number of listings, transactions, and user engagement.</span></li>
                                    </ul>
                                </div>
                                <div className="achievement__item">
                                    <i><FcApproval /></i>
                                    <h3>User & Seller Management</h3>
                                    <ul>
                                        <li><span><b>User Profiles:</b> Manage user accounts and profiles with detailed information.</span></li>
                                        <li><span><b>Seller Verification:</b> Implement a process for verifying seller profiles for authenticity.</span></li>
                                    </ul>
                                </div>
                                <div className="achievement__item">
                                    <i><FcApproval /></i>
                                    <h3>Listing Management</h3>
                                    <ul>
                                        <li><span><b>Product Listings:</b> Monitor and manage listings of spare parts and vehicles.</span></li>
                                        <li><span><b>Approval Process:</b> Implement an approval process for new listings to ensure quality.</span></li>
                                    </ul>
                                </div>
                                <div className="achievement__item">
                                    <i><FcApproval /></i>
                                    <h3>Transaction Management</h3>
                                    <ul>
                                        <li><span><b>Transaction Tracking:</b> Track and manage transactions, including purchases and payments.</span></li>
                                        <li><span><b>Refund & Dispute Resolution:</b> Handle refunds and disputes between buyers and sellers.</span></li>
                                    </ul>
                                </div>
                                <div className="achievement__item">
                                    <i><FcApproval /></i>
                                    <h3>Seller Ratings & Reviews</h3>
                                    <ul>
                                        <li><span><b>Review Moderation:</b> Moderate and manage user reviews to ensure authenticity.</span></li>
                                        <li><span><b>Seller Performance Reports:</b> Generate reports on seller performance based on ratings and feedback.</span></li>
                                    </ul>
                                </div>
                                <div className="achievement__item">
                                    <i><FcApproval /></i>
                                    <h3>Communication Tools</h3>
                                    <ul>
                                        <li><span><b>Messaging Interface:</b> Facilitate communication between buyers and sellers through an integrated messaging system.</span></li>
                                        <li><span><b>Notification Management:</b> Send platform-wide notifications for announcements or updates.</span></li>
                                    </ul>
                                </div>
                                <div className="achievement__item">
                                    <i><FcApproval /></i>
                                    <h3>Inventory & Stock Management</h3>
                                    <ul>
                                        <li><span><b>Spare Parts Inventory:</b> Manage an inventory of spare parts available on the platform.</span></li>
                                        <li><span><b>Stock Tracking:</b> Monitor stock levels and restocking requirements.</span></li>
                                    </ul>
                                </div>
                                <div className="achievement__item">
                                    <i><FcApproval /></i>
                                    <h3>Geo-Location Management</h3>
                                    <ul>
                                        <li><span><b>Location-Based Insights:</b> Analyze user and seller demographics based on geographic locations.</span></li>
                                        <li><span><b>Targeted Marketing:</b> Implement location-based marketing strategies.</span></li>
                                    </ul>
                                </div>
                                <div className="achievement__item">
                                    <i><FcApproval /></i>
                                    <h3>Auction & Bidding Management (Optional)</h3>
                                    <ul>
                                        <li><span><b>Auction Setup:</b> Manage online auctions for specific spare parts or vehicles.</span></li>
                                        <li><span><b>Bidding Controls:</b> Implement controls for managing real-time bidding activities.</span></li>
                                    </ul>
                                </div>
                                <div className="achievement__item">
                                    <i><FcApproval /></i>
                                    <h3>Seller Onboarding & Training</h3>
                                    <ul>
                                        <li><span><b>Onboarding Process:</b> Facilitate the onboarding of new sellers with an easy registration process.</span></li>
                                        <li><span><b>Training Resources:</b> Provide guides and resources for sellers to optimize listings.</span></li>
                                    </ul>
                                </div>
                                <div className="achievement__item">
                                    <i><FcApproval /></i>
                                    <h3>Transaction Security</h3>
                                    <ul>
                                        <li><span><b>Payment Gateways Integration:</b> Integrate secure payment gateways for transactions.</span></li>
                                        <li><span><b>Fraud Prevention Measures:</b> Implement measures to prevent fraudulent activities.</span></li>
                                    </ul>
                                </div>
                                <div className="achievement__item">
                                    <i><FcApproval /></i>
                                    <h3>Document Verification</h3>
                                    <ul>
                                        <li><span><b>Document Submission:</b> Collect and verify necessary documents from sellers.</span></li>
                                        <li><span><b>Compliance Checks:</b> Ensure compliance with legal and regulatory requirements.</span></li>
                                    </ul>
                                </div>
                                <div className="achievement__item">
                                    <i><FcApproval /></i>
                                    <h3>Reporting & Analytics</h3>
                                    <ul>
                                        <li><span><b>Platform-wide Reports:</b> Generate reports on platform performance, user behavior, and financial metrics.</span></li>
                                        <li><span><b>Trend Analysis:</b> Analyze trends in user preferences and popular listings.</span></li>
                                    </ul>
                                </div>
                                <div className="achievement__item">
                                    <i><FcApproval /></i>
                                    <h3>Promotions & Loyalty Programs</h3>
                                    <ul>
                                        <li><span><b>Promotional Campaigns:</b> Create and manage promotions to attract more buyers and sellers.</span></li>
                                        <li><span><b>Loyalty Programs:</b> Implement programs to reward regular users and sellers.</span></li>
                                    </ul>
                                </div>
                                <div className="achievement__item">
                                    <i><FcApproval /></i>
                                    <h3>Mobile App Administration</h3>
                                    <ul>
                                        <li><span><b>Mobile App Management:</b> Oversee the administration of the platform's mobile application.</span></li>
                                        <li><span><b>Push Notification Management:</b> Control push notifications for important updates.</span></li>
                                    </ul>
                                </div>
                                <div className="achievement__item">
                                    <i><FcApproval /></i>
                                    <h3>Feedback & Improvement Strategies</h3>
                                    <ul>
                                        <li><span><b>Feedback Analysis:</b> Analyze user feedback for continuous improvement.</span></li>
                                        <li><span><b>Feature Suggestions:</b> Collect and evaluate suggestions for platform enhancements.</span></li>
                                    </ul>
                                </div>
                                <div className="achievement__item">
                                    <i><FcApproval /></i>
                                    <h3>Community Forums Management</h3>
                                    <ul>
                                        <li><span><b>Moderation:</b> Oversee and moderate discussions within the platform's community forums.</span></li>
                                        <li><span><b>Engagement Strategies:</b> Implement strategies to encourage user engagement.</span></li>
                                    </ul>
                                </div>
                                <div className="achievement__item">
                                    <i><FcApproval /></i>
                                    <h3>Membership Programs Administration (Optional)</h3>
                                    <ul>
                                        <li><span><b>Membership Plans:</b> Administer membership programs and benefits.</span></li>
                                        <li><span><b>Membership Performance Reports:</b> Evaluate the success and impact of membership programs.</span></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="services__area pt-100 pb-100">
                <div className="container">
                    <div className="row">
                        <div className="col-xl-12">
                            <div className="section__title section__title-4 text-center mb-55">
                                <h2>Available Platforms</h2>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-xl-4">
                            <div className="services__item mb-15 mb-lg-0">
                                <div className="services__icon mb-20">
                                    <img src="assets/img/platform-icon1.jpg" alt="Sjain" />
                                </div>
                                <div className="services__content">
                                    <h3>Web Panel for End User</h3>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-4">
                            <div className="services__item mb-15 mb-lg-0">
                                <div className="services__icon mb-20">
                                    <img src="assets/img/platform-icon2.jpg" alt="Sjain" />
                                </div>
                                <div className="services__content">
                                    <h3>Mobile App for End User</h3>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-4">
                            <div className="services__item">
                                <div className="services__icon mb-20">
                                    <img src="assets/img/platform-icon4.jpg" alt="Sjain" />
                                </div>
                                <div className="services__content">
                                    <h3>Web Panel for Admin</h3>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default ContentSection;