import React from 'react';

const today = new Date();

const Footer = () => {
    return (
        <>
            <footer>
                <div className="footer__area">
                    <div className="container">
                        <div className="footer__copyright-inner">
                            <div className="row">
                                <div className="col-xl-12">
                                    <div className="footer__copyright-text text-center">
                                        <p>Sjain Ventures &copy; {today.getFullYear()} - All Rights Reserved.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
        </>
    );
};

export default Footer;