import React, { useState } from 'react';
import { FaLongArrowAltUp } from "react-icons/fa";

const BackToTop = () => {

    const [visible, setVisible] = useState(false)

    const toggleVisible = () => {
        const scrolled = document.documentElement.scrollTop;
        if (scrolled > 300) {
            setVisible(true)
        }
        else if (scrolled <= 300) {
            setVisible(false)
        }
    };

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    };

    window.addEventListener('scroll', toggleVisible);

    return (
        <div className="back-to-top">
            <button type="button" onClick={scrollToTop} style={{ display: visible ? 'block' : 'none', opacity: visible ? '1' : '0' }}>
                <FaLongArrowAltUp />
            </button>
        </div>
    );
}

export default BackToTop;