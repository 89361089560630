import React from 'react';
import { FcApproval } from "react-icons/fc";

const ContentSection = () => {
    const scrollTo = (id) => {
        const element = document.getElementById(id);
        element.scrollIntoView({
            behavior: "smooth",
        });
    };
    return (
        <>
            <section className="achievement__area sticky">
                <div className="container">
                    <div className="row">
                        <div className="col-xl-12">
                            <div className="scrollLinks">
                                <button onClick={() => scrollTo("enduser")}>User Side Features</button>
                                <button onClick={() => scrollTo("garage")}>Garage Side Features</button>
                                <button onClick={() => scrollTo("admin")}>Admin Side Features</button>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="achievement__area pt-100 pb-100 fixBox" id="enduser">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-xl-12">
                            <div className="section__title section__title-4 text-center mb-55">
                                <h2>User Side Features of Vehicle Garage Management System</h2>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-xl-12">
                            <div className="achievement__wrapper">
                                <div className="achievement__item">
                                    <i><FcApproval /></i>
                                    <h3>User Registration & Profile</h3>
                                    <ul>
                                        <li><span><b>Account Creation:</b> Easily create a user account with personal details.</span></li>
                                        <li><span><b>Profile Management:</b> Update contact information and preferences.</span></li>
                                    </ul>
                                </div>
                                <div className="achievement__item">
                                    <i><FcApproval /></i>
                                    <h3>Vehicle Check-In/Check-Out</h3>
                                    <ul>
                                        <li><span><b>Check-In Requests:</b> Initiate vehicle check-in requests for maintenance or repairs.</span></li>
                                        <li><span><b>Check-Out Requests:</b> Request the release of a vehicle from the garage after maintenance.</span></li>
                                    </ul>
                                </div>
                                <div className="achievement__item">
                                    <i><FcApproval /></i>
                                    <h3>Service History Access</h3>
                                    <ul>
                                        <li><span><b>View Service Records:</b> Access a comprehensive history of services and repairs for each vehicle.</span></li>
                                        <li><span><b>Upcoming Maintenance:</b> Receive notifications for upcoming scheduled maintenance.</span></li>
                                    </ul>
                                </div>
                                <div className="achievement__item">
                                    <i><FcApproval /></i>
                                    <h3>Appointment Scheduling</h3>
                                    <ul>
                                        <li><span><b>Online Appointment Booking:</b> Schedule maintenance appointments online.</span></li>
                                        <li><span><b>Appointment Confirmation:</b> Receive immediate confirmation and details of scheduled appointments.</span></li>
                                    </ul>
                                </div>
                                <div className="achievement__item">
                                    <i><FcApproval /></i>
                                    <h3>Digital Work Orders</h3>
                                    <ul>
                                        <li><span><b>Work Order Requests:</b> Submit digital work orders for specific repairs or services.</span></li>
                                        <li><span><b>Work Order Status:</b> Track the status of submitted work orders.</span></li>
                                    </ul>
                                </div>
                                <div className="achievement__item">
                                    <i><FcApproval /></i>
                                    <h3>Vehicle Status Updates</h3>
                                    <ul>
                                        <li><span><b>Real-Time Updates:</b> Receive real-time updates on the status of ongoing maintenance or repairs.</span></li>
                                        <li><span><b>Completion Notifications:</b> Get notified when the vehicle maintenance is completed.</span></li>
                                    </ul>
                                </div>
                                <div className="achievement__item">
                                    <i><FcApproval /></i>
                                    <h3>Document Management</h3>
                                    <ul>
                                        <li><span><b>Digital Documents Access:</b> Access and download digital copies of service invoices and receipts.</span></li>
                                        <li><span><b>Insurance & Registration Documents:</b> View and verify the status of insurance and registration documents.</span></li>
                                    </ul>
                                </div>
                                <div className="achievement__item">
                                    <i><FcApproval /></i>
                                    <h3>Communication Tools</h3>
                                    <ul>
                                        <li><span><b>Messaging System:</b> Communicate with garage administrators or technicians through an integrated messaging system.</span></li>
                                        <li><span><b>Notification Center:</b> Receive alerts and notifications related to appointment reminders and vehicle updates.</span></li>
                                    </ul>
                                </div>
                                <div className="achievement__item">
                                    <i><FcApproval /></i>
                                    <h3>Cost Estimates</h3>
                                    <ul>
                                        <li><span><b>Request Cost Estimates:</b> Obtain estimates for planned maintenance or repairs.</span></li>
                                        <li><span><b>Transparent Pricing:</b> Access clear and transparent pricing information for services.</span></li>
                                    </ul>
                                </div>
                                <div className="achievement__item">
                                    <i><FcApproval /></i>
                                    <h3>Payment Integration</h3>
                                    <ul>
                                        <li><span><b>Secure Online Payments:</b> Make secure online payments for completed services or repairs.</span></li>
                                        <li><span><b>Digital Receipts:</b> Receive digital receipts for all transactions.</span></li>
                                    </ul>
                                </div>
                                <div className="achievement__item">
                                    <i><FcApproval /></i>
                                    <h3>Feedback & Ratings</h3>
                                    <ul>
                                        <li><span><b>Provide Feedback:</b> Share feedback on the quality of services and overall experience.</span></li>
                                        <li><span><b>Rating System:</b> Rate the service provided by the garage.</span></li>
                                    </ul>
                                </div>
                                <div className="achievement__item">
                                    <i><FcApproval /></i>
                                    <h3>Mobile Accessibility</h3>
                                    <ul>
                                        <li><span><b>Mobile App Access:</b> Conveniently access the garage management system through a dedicated mobile application.</span></li>
                                        <li><span><b>Mobile Alerts:</b> Receive push notifications for appointment updates and reminders.</span></li>
                                    </ul>
                                </div>
                                <div className="achievement__item">
                                    <i><FcApproval /></i>
                                    <h3>Vehicle Pickup & Drop-off Services</h3>
                                    <ul>
                                        <li><span><b>Request Pickup/Drop-off:</b> Request vehicle pickup for scheduled services and drop-off after completion.</span></li>
                                        <li><span><b>Tracking Service Vehicles:</b> Track the location of service vehicles during pickup and drop-off.</span></li>
                                    </ul>
                                </div>
                                <div className="achievement__item">
                                    <i><FcApproval /></i>
                                    <h3>Emergency Assistance</h3>
                                    <ul>
                                        <li><span><b>Emergency Service Requests:</b> Initiate emergency service requests for urgent vehicle issues.</span></li>
                                        <li><span><b>Immediate Response:</b> Receive immediate assistance or towing services.</span></li>
                                    </ul>
                                </div>
                                <div className="achievement__item">
                                    <i><FcApproval /></i>
                                    <h3>Membership Programs</h3>
                                    <ul>
                                        <li><span><b>Participate in Membership:</b> Enroll in membership programs for additional benefits and discounts.</span></li>
                                        <li><span><b>Exclusive Offers:</b> Receive exclusive offers and discounts for loyal customers.</span></li>
                                    </ul>
                                </div>
                                <div className="achievement__item">
                                    <i><FcApproval /></i>
                                    <h3>Training Resources</h3>
                                    <ul>
                                        <li><span><b>Guides & Tutorials:</b> Access guides and tutorials for basic vehicle maintenance.</span></li>
                                        <li><span><b>Tips & Recommendations:</b> Receive tips and recommendations for optimal vehicle care.</span></li>
                                    </ul>
                                </div>
                                <div className="achievement__item">
                                    <i><FcApproval /></i>
                                    <h3>Community Forums</h3>
                                    <ul>
                                        <li><span><b>User Discussions:</b> Engage in discussions with other users about their experiences with the garage.</span></li>
                                        <li><span><b>Local Insights:</b> Share insights about local automotive services and experiences.</span></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="achievement__area pt-100 pb-100 fixBox grey-bg-12" id="garage">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-xl-12">
                            <div className="section__title section__title-4 text-center mb-55">
                                <h2>Garage Side Features of Vehicle Garage Management System</h2>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-xl-12">
                            <div className="achievement__wrapper">
                                <div className="achievement__item">
                                    <i><FcApproval /></i>
                                    <h3>Dashboard & Overview</h3>
                                    <ul>
                                        <li><span><b>Comprehensive Dashboard:</b> Access a centralized dashboard displaying real-time insights into garage operations.</span></li>
                                        <li><span><b>Quick Stats:</b> View key metrics, including the number of vehicles serviced, upcoming appointments, and revenue.</span></li>
                                    </ul>
                                </div>
                                <div className="achievement__item">
                                    <i><FcApproval /></i>
                                    <h3>Vehicle Management</h3>
                                    <ul>
                                        <li><span><b>Vehicle Profiles:</b> Manage and update detailed profiles for each serviced vehicle.</span></li>
                                        <li><span><b>Service History Logs:</b> Maintain a comprehensive log of services and repairs for each vehicle.</span></li>
                                    </ul>
                                </div>
                                <div className="achievement__item">
                                    <i><FcApproval /></i>
                                    <h3>Appointment Management</h3>
                                    <ul>
                                        <li><span><b>Appointment Calendar:</b> Monitor and manage the overall appointment schedule for vehicle maintenance.</span></li>
                                        <li><span><b>Appointment Allocation:</b> Assign appointments to available service bays and technicians.</span></li>
                                    </ul>
                                </div>
                                <div className="achievement__item">
                                    <i><FcApproval /></i>
                                    <h3>Service Request Management</h3>
                                    <ul>
                                        <li><span><b>Digital Work Orders:</b> Receive and manage digital work orders submitted by users.</span></li>
                                        <li><span><b>Work Order Status:</b> Track the status of ongoing work orders and services.</span></li>
                                    </ul>
                                </div>
                                <div className="achievement__item">
                                    <i><FcApproval /></i>
                                    <h3>Technician & Staff Management</h3>
                                    <ul>
                                        <li><span><b>Technician Profiles:</b> Maintain profiles for each technician, including skills and certifications.</span></li>
                                        <li><span><b>Workload Distribution:</b> Assign tasks and distribute workload among technicians.</span></li>
                                    </ul>
                                </div>
                                <div className="achievement__item">
                                    <i><FcApproval /></i>
                                    <h3>Inventory & Parts Management</h3>
                                    <ul>
                                        <li><span><b>Spare Parts Inventory:</b> Manage an inventory of spare parts and track stock levels.</span></li>
                                        <li><span><b>Restocking Alerts:</b> Receive alerts for low-stock spare parts.</span></li>
                                    </ul>
                                </div>
                                <div className="achievement__item">
                                    <i><FcApproval /></i>
                                    <h3>Billing & Invoicing</h3>
                                    <ul>
                                        <li><span><b>Invoice Generation:</b> Generate invoices for completed services and repairs.</span></li>
                                        <li><span><b>Payment Tracking:</b> Monitor payment status and overdue payments.</span></li>
                                    </ul>
                                </div>
                                <div className="achievement__item">
                                    <i><FcApproval /></i>
                                    <h3>Document Verification</h3>
                                    <ul>
                                        <li><span><b>Verification of Documents:</b> Verify the authenticity of user-submitted documents, such as driving licenses.</span></li>
                                        <li><span><b>Insurance & Registration Checks:</b> Ensure that vehicles have up-to-date insurance and registration documents.</span></li>
                                    </ul>
                                </div>
                                <div className="achievement__item">
                                    <i><FcApproval /></i>
                                    <h3>Communication Tools</h3>
                                    <ul>
                                        <li><span><b>Messaging Interface:</b> Communicate with users, technicians, and staff through an integrated messaging system.</span></li>
                                        <li><span><b>Notification Management:</b> Send notifications to users regarding appointment updates, promotions, or service alerts.</span></li>
                                    </ul>
                                </div>
                                <div className="achievement__item">
                                    <i><FcApproval /></i>
                                    <h3>Reporting & Analytics</h3>
                                    <ul>
                                        <li><span><b>Service Reports:</b> Generate reports on service trends, revenue, and user demographics.</span></li>
                                        <li><span><b>Technician Performance:</b> Analyze the performance of individual technicians and service bays.</span></li>
                                    </ul>
                                </div>
                                <div className="achievement__item">
                                    <i><FcApproval /></i>
                                    <h3>Security & Access Control</h3>
                                    <ul>
                                        <li><span><b>Role-Based Access:</b> Implement role-based access controls for different admin users.</span></li>
                                        <li><span><b>Data Security Measures:</b> Ensure the security of user and transaction data.</span></li>
                                    </ul>
                                </div>
                                <div className="achievement__item">
                                    <i><FcApproval /></i>
                                    <h3>Maintenance Planning</h3>
                                    <ul>
                                        <li><span><b>Scheduled Maintenance:</b> Plan and schedule regular maintenance for service bays and equipment.</span></li>
                                        <li><span><b>Equipment Logs:</b> Maintain logs for the maintenance of garage equipment.</span></li>
                                    </ul>
                                </div>
                                <div className="achievement__item">
                                    <i><FcApproval /></i>
                                    <h3>Customer Support Integration</h3>
                                    <ul>
                                        <li><span><b>Support Ticket System:</b> Integrate a support ticket system for efficient query resolution.</span></li>
                                        <li><span><b>FAQ Management:</b> Manage and update a database of frequently asked questions.</span></li>
                                    </ul>
                                </div>
                                <div className="achievement__item">
                                    <i><FcApproval /></i>
                                    <h3>Promotions & Loyalty Programs</h3>
                                    <ul>
                                        <li><span><b>Promotional Campaigns:</b> Create and manage promotional campaigns to attract new customers.</span></li>
                                        <li><span><b>Loyalty Programs:</b> Implement loyalty programs to reward repeat customers.</span></li>
                                    </ul>
                                </div>
                                <div className="achievement__item">
                                    <i><FcApproval /></i>
                                    <h3>Mobile Accessibility</h3>
                                    <ul>
                                        <li><span><b>Mobile App Access:</b> Access the garage management system through a dedicated mobile application.</span></li>
                                        <li><span><b>Mobile Alerts:</b> Receive push notifications for important updates and reminders.</span></li>
                                    </ul>
                                </div>
                                <div className="achievement__item">
                                    <i><FcApproval /></i>
                                    <h3>Feedback & Ratings Management</h3>
                                    <ul>
                                        <li><span><b>Review Moderation:</b> Moderate and manage user reviews to ensure authenticity.</span></li>
                                        <li><span><b>Continuous Improvement:</b> Use feedback to identify areas for improvement in services.</span></li>
                                    </ul>
                                </div>
                                <div className="achievement__item">
                                    <i><FcApproval /></i>
                                    <h3>Emergency Service Management</h3>
                                    <ul>
                                        <li><span><b>Emergency Response Coordination:</b> Manage emergency service requests and coordinate rapid responses.</span></li>
                                        <li><span><b>Towing Services:</b> Coordinate towing services for vehicles in emergency situations.</span></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="achievement__area pt-100 pb-100 fixBox" id="admin">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-xl-12">
                            <div className="section__title section__title-4 text-center mb-55">
                                <h2>Admin Side Features of Vehicle Garage Management System</h2>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-xl-12">
                            <div className="achievement__wrapper">
                                <div className="achievement__item">
                                    <i><FcApproval /></i>
                                    <h3>Dashboard & Platform Overview</h3>
                                    <ul>
                                        <li><span><b>Comprehensive Dashboard:</b> Access a centralized dashboard displaying real-time insights into multiple garage operations.</span></li>
                                        <li><span><b>Platform-wide Metrics:</b> View key metrics such as the total number of serviced vehicles, revenue, and user engagement.</span></li>
                                    </ul>
                                </div>
                                <div className="achievement__item">
                                    <i><FcApproval /></i>
                                    <h3>Multi-Garage Management</h3>
                                    <ul>
                                        <li><span><b>Garage Profiles:</b> Manage profiles and details for each garage within the platform.</span></li>
                                        <li><span><b>Unified Reporting:</b> Generate consolidated reports across all affiliated garages.</span></li>
                                    </ul>
                                </div>
                                <div className="achievement__item">
                                    <i><FcApproval /></i>
                                    <h3>User Management Across Garages</h3>
                                    <ul>
                                        <li><span><b>User Profiles:</b> Maintain profiles for users with roles spanning multiple garages.</span></li>
                                        <li><span><b>Access Control:</b> Implement access controls for users based on roles and responsibilities.</span></li>
                                    </ul>
                                </div>
                                <div className="achievement__item">
                                    <i><FcApproval /></i>
                                    <h3>Cross-Garage Communication</h3>
                                    <ul>
                                        <li><span><b>Messaging Interface:</b> Facilitate communication between platform administrators and individual garage administrators.</span></li>
                                        <li><span><b>Notification Management:</b> Send platform-wide notifications for announcements or updates.</span></li>
                                    </ul>
                                </div>
                                <div className="achievement__item">
                                    <i><FcApproval /></i>
                                    <h3>Unified Reporting & Analytics</h3>
                                    <ul>
                                        <li><span><b>Cross-Garage Reports:</b> Generate platform-wide reports on service trends, revenue, and user demographics.</span></li>
                                        <li><span><b>Performance Comparison:</b> Compare the performance of different garages within the platform.</span></li>
                                    </ul>
                                </div>
                                <div className="achievement__item">
                                    <i><FcApproval /></i>
                                    <h3>Billing & Financial Integration</h3>
                                    <ul>
                                        <li><span><b>Unified Billing System:</b> Implement a unified billing system for financial transactions across multiple garages.</span></li>
                                        <li><span><b>Revenue Distribution:</b> Track and distribute revenue among affiliated garages.</span></li>
                                    </ul>
                                </div>
                                <div className="achievement__item">
                                    <i><FcApproval /></i>
                                    <h3>Document Verification & Compliance</h3>
                                    <ul>
                                        <li><span><b>Document Verification:</b> Implement a standardized process for verifying user-submitted documents across garages.</span></li>
                                        <li><span><b>Regulatory Compliance:</b> Ensure compliance with regulations and standards across all affiliated garages.</span></li>
                                    </ul>
                                </div>
                                <div className="achievement__item">
                                    <i><FcApproval /></i>
                                    <h3>Platform-Wide Promotions & Loyalty Programs</h3>
                                    <ul>
                                        <li><span><b>Promotional Campaigns:</b> Create and manage promotions that can be applied across all affiliated garages.</span></li>
                                        <li><span><b>Loyalty Programs:</b> Implement loyalty programs that offer rewards and benefits across the platform.</span></li>
                                    </ul>
                                </div>
                                <div className="achievement__item">
                                    <i><FcApproval /></i>
                                    <h3>Consolidated Customer Support</h3>
                                    <ul>
                                        <li><span><b>Support Ticket System:</b> Centralize the support ticket system for efficient query resolution.</span></li>
                                        <li><span><b>Platform-wide FAQs:</b> Manage a centralized database of frequently asked questions.</span></li>
                                    </ul>
                                </div>
                                <div className="achievement__item">
                                    <i><FcApproval /></i>
                                    <h3>Cross-Garage Integration Capabilities</h3>
                                    <ul>
                                        <li><span><b>Third-Party Integrations:</b> Facilitate integrations with external systems consistently across all garages.</span></li>
                                        <li><span><b>API Access:</b> Provide API access for seamless integration with other enterprise applications.</span></li>
                                    </ul>
                                </div>
                                <div className="achievement__item">
                                    <i><FcApproval /></i>
                                    <h3>Platform-Wide Maintenance Planning</h3>
                                    <ul>
                                        <li><span><b>Scheduled Maintenance:</b> Plan and schedule regular maintenance activities that apply to all affiliated garages.</span></li>
                                        <li><span><b>Equipment Logs:</b> Maintain logs for the maintenance of shared equipment.</span></li>
                                    </ul>
                                </div>
                                <div className="achievement__item">
                                    <i><FcApproval /></i>
                                    <h3>Unified Emergency Service Management</h3>
                                    <ul>
                                        <li><span><b>Emergency Response Coordination:</b> Manage emergency service requests centrally and coordinate rapid responses.</span></li>
                                        <li><span><b>Towing Services:</b> Coordinate towing services for vehicles in emergency situations across the platform.</span></li>
                                    </ul>
                                </div>
                                <div className="achievement__item">
                                    <i><FcApproval /></i>
                                    <h3>Platform-Wide Feedback & Ratings Management</h3>
                                    <ul>
                                        <li><span><b>Review Moderation:</b> Centrally manage user reviews to ensure authenticity.</span></li>
                                        <li><span><b>Continuous Improvement:</b> Use feedback to identify areas for improvement in services across all garages.</span></li>
                                    </ul>
                                </div>
                                <div className="achievement__item">
                                    <i><FcApproval /></i>
                                    <h3>Cross-Garage Security & Access Control</h3>
                                    <ul>
                                        <li><span><b>Role-Based Access:</b> Implement consistent role-based access controls for users across the platform.</span></li>
                                        <li><span><b>Data Security Measures:</b> Ensure consistent data security standards are maintained.</span></li>
                                    </ul>
                                </div>
                                <div className="achievement__item">
                                    <i><FcApproval /></i>
                                    <h3>Consolidated Platform-wide Maintenance Planning</h3>
                                    <ul>
                                        <li><span><b>Scheduled Maintenance:</b> Plan and schedule regular maintenance activities that apply across all affiliated garages.</span></li>
                                        <li><span><b>Equipment Logs:</b> Maintain logs for the maintenance of shared equipment.</span></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="services__area pt-100 pb-100 grey-bg-12">
                <div className="container">
                    <div className="row">
                        <div className="col-xl-12">
                            <div className="section__title section__title-4 text-center mb-55">
                                <h2>Available Platforms</h2>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-xl-4">
                            <div className="services__item mb-15 mb-lg-0">
                                <div className="services__icon mb-20">
                                    <img src="assets/img/platform-icon2.jpg" alt="Sjain" />
                                </div>
                                <div className="services__content">
                                    <h3>Mobile App for End User</h3>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-4">
                            <div className="services__item mb-15 mb-lg-0">
                                <div className="services__icon mb-20">
                                    <img src="assets/img/platform-icon2.jpg" alt="Sjain" />
                                </div>
                                <div className="services__content">
                                    <h3>Mobile App for Garage</h3>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-4">
                            <div className="services__item">
                                <div className="services__icon mb-20">
                                    <img src="assets/img/platform-icon4.jpg" alt="Sjain" />
                                </div>
                                <div className="services__content">
                                    <h3>Web Panel for Admin</h3>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default ContentSection;