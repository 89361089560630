import React from 'react';

const ContentSection = () => {
    return (
        <>
            <section className="blog__area pt-100 pb-100">
                <div className="container">
                    <div className="row">
                        <div className="col-xl-12">
                            <div className="blog__details-wrapper">
                                <div className="blog__text">
                                    <h3>&nbsp;</h3>
                                    <p>&nbsp;</p>
                                    <p className="mb-0">&nbsp;</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div >
            </section >
        </>
    );
};

export default ContentSection;