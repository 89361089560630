import React from 'react';

const FaqSection = () => {
    return (
        <>
            <section className="faq__area pt-100 pb-100 grey-bg-12">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-xl-12">
                            <div className="section__title section__title-3 text-center mb-55">
                                <h2>Frequently Asked Questions</h2>
                            </div>
                        </div>
                    </div>
                    <div className="row" id="accordionExample">
                        <div className="col-xl-6">
                            <div className="faq__accordion">
                                <div className="accordion">
                                    <div className="card">
                                        <div className="card-header accordion-header" id="acc_1" >
                                            <h5 className="mb-0">
                                                <button className="btn btn-link collapsed" data-bs-toggle="collapse" data-bs-target="#collapse_1" aria-expanded="true" aria-controls="collapse_1">
                                                    What is a Vehicle Rental Management System?
                                                </button>
                                            </h5>
                                        </div>
                                        <div id="collapse_1" className="collapse" aria-labelledby="acc_1" data-bs-parent="#accordionExample">
                                            <div className="card-body accordion-body">
                                                <p>A Vehicle Rental Management System is a software solution designed to facilitate the efficient management of vehicle rental operations, including reservation handling, booking management, and overall fleet optimization.</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="card">
                                        <div className="card-header" id="acc_2">
                                            <h5 className="mb-0">
                                                <button className="btn btn-link collapsed" data-bs-toggle="collapse" data-bs-target="#collapse_2" aria-expanded="true" aria-controls="collapse_2">
                                                    How does the Fleet Management System contribute to efficient vehicle operations?
                                                </button>
                                            </h5>
                                        </div>
                                        <div id="collapse_2" className="collapse" aria-labelledby="acc_2" data-bs-parent="#accordionExample">
                                            <div className="card-body accordion-body">
                                                <p>The Fleet Management System is designed to streamline the oversight of a fleet, encompassing vehicle tracking, maintenance scheduling, and performance analytics, ultimately improving operational efficiency.</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="card">
                                        <div className="card-header" id="acc_3">
                                            <h5 className="mb-0">
                                                <button className="btn btn-link collapsed" data-bs-toggle="collapse" data-bs-target="#collapse_3" aria-expanded="true" aria-controls="collapse_3">
                                                    What functionalities does the Garage Management System offer?
                                                </button>
                                            </h5>
                                        </div>
                                        <div id="collapse_3" className="collapse" aria-labelledby="acc_3" data-bs-parent="#accordionExample">
                                            <div className="card-body accordion-body">
                                                <p>The Garage Management System provides tools for organized garage operations, covering maintenance schedules, repair history tracking, and inventory management of spare parts.</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="card">
                                        <div className="card-header" id="acc_4">
                                            <h5 className="mb-0">
                                                <button className="btn btn-link collapsed" data-bs-toggle="collapse" data-bs-target="#collapse_4" aria-expanded="true" aria-controls="collapse_4">
                                                    How does the Vehicle and Spare Part Buy/Sell Platform function?
                                                </button>
                                            </h5>
                                        </div>
                                        <div id="collapse_4" className="collapse" aria-labelledby="acc_4" data-bs-parent="#accordionExample">
                                            <div className="card-body accordion-body">
                                                <p>The Vehicle and Spare Part Buy/Sell Platform serves as an online marketplace for buying and selling vehicles and related spare parts, creating a convenient and efficient platform for users.</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="card mb-4 mb-lg-0">
                                        <div className="card-header" id="acc_5">
                                            <h5 className="mb-0">
                                                <button className="btn btn-link collapsed" data-bs-toggle="collapse" data-bs-target="#collapse_5" aria-expanded="true" aria-controls="collapse_5">
                                                    Can I track the real-time location of vehicles in the Fleet Management System?
                                                </button>
                                            </h5>
                                        </div>
                                        <div id="collapse_5" className="collapse" aria-labelledby="acc_5" data-bs-parent="#accordionExample">
                                            <div className="card-body accordion-body">
                                                <p>Yes, the Fleet Management System includes features for real-time vehicle tracking, offering precise location information for improved logistics and security.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-6">
                            <div className="faq__accordion">
                                <div className="accordion">
                                    <div className="card">
                                        <div className="card-header" id="acc_6">
                                            <h5 className="mb-0">
                                                <button className="btn btn-link collapsed" data-bs-toggle="collapse" data-bs-target="#collapse_6" aria-expanded="true" aria-controls="collapse_6">
                                                    How does the Vehicle Rental Management System handle reservations?
                                                </button>
                                            </h5>
                                        </div>
                                        <div id="collapse_6" className="collapse" aria-labelledby="acc_6" data-bs-parent="#accordionExample">
                                            <div className="card-body accordion-body">
                                                <p>The Vehicle Rental Management System simplifies the reservation process, providing an integrated system for users to book and manage vehicle rentals efficiently.</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="card">
                                        <div className="card-header" id="acc_7">
                                            <h5 className="mb-0">
                                                <button className="btn btn-link collapsed" data-bs-toggle="collapse" data-bs-target="#collapse_7" aria-expanded="true" aria-controls="collapse_7">
                                                    What role does the Garage Management System play in vehicle maintenance?
                                                </button>
                                            </h5>
                                        </div>
                                        <div id="collapse_7" className="collapse" aria-labelledby="acc_7" data-bs-parent="#accordionExample">
                                            <div className="card-body accordion-body">
                                                <p>The Garage Management System assists in organizing and overseeing vehicle maintenance by managing schedules, repair histories, and spare part inventories.</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="card">
                                        <div className="card-header" id="acc_8">
                                            <h5 className="mb-0">
                                                <button className="btn btn-link collapsed" data-bs-toggle="collapse" data-bs-target="#collapse_8" aria-expanded="true" aria-controls="collapse_8">
                                                    How does the Vehicle and Spare Part Buy/Sell Platform ensure secure transactions?
                                                </button>
                                            </h5>
                                        </div>
                                        <div id="collapse_8" className="collapse" aria-labelledby="acc_8" data-bs-parent="#accordionExample">
                                            <div className="card-body accordion-body">
                                                <p>The Vehicle and Spare Part Buy/Sell Platform often implements secure transaction protocols, ensuring the safety of buying and selling activities on the platform.</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="card">
                                        <div className="card-header" id="acc_9">
                                            <h5 className="mb-0">
                                                <button className="btn btn-link collapsed" data-bs-toggle="collapse" data-bs-target="#collapse_9" aria-expanded="true" aria-controls="collapse_9">
                                                    Can I receive automated alerts for maintenance schedules through the Fleet Management System?
                                                </button>
                                            </h5>
                                        </div>
                                        <div id="collapse_9" className="collapse" aria-labelledby="acc_9" data-bs-parent="#accordionExample">
                                            <div className="card-body accordion-body">
                                                <p>Yes, the Fleet Management System typically offers automated alerts for maintenance schedules, reducing the likelihood of unexpected breakdowns through proactive maintenance.</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="card">
                                        <div className="card-header" id="acc_10">
                                            <h5 className="mb-0">
                                                <button className="btn btn-link collapsed" data-bs-toggle="collapse" data-bs-target="#collapse_10" aria-expanded="true" aria-controls="collapse_10">
                                                    What benefits does the Vehicle Rental Management System bring to the customer experience?
                                                </button>
                                            </h5>
                                        </div>
                                        <div id="collapse_10" className="collapse" aria-labelledby="acc_10" data-bs-parent="#accordionExample">
                                            <div className="card-body accordion-body">
                                                <p>The Vehicle Rental Management System enhances the customer experience by providing a user-friendly interface for reservations, efficient booking management, and streamlined rental processes.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default FaqSection;