import Slider from "react-slick";
import SingleBrandItem from "./ClientSingle";

const ClientSection = () => {
    // slick setting
    const settings = {
        autoplay: true,
        autoplaySpeed: 3000,
        dots: false,
        arrows: false,
        slidesToShow: 5,
        slidesToScroll: 2,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 5,
                    slidesToScroll: 2,
                    infinite: true,
                }
            },
            {
                breakpoint: 767,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 2,
                }
            },
            {
                breakpoint: 576,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                }
            },
        ]

    };
    return (
        <>
            <section className="brand__area pt-100 pb-100 grey-bg-12">
                <div className="container">
                    <div className="row">
                        <div className="col-xl-12">
                            <div className="section__title section__title text-center mb-55">
                                <h2>Our Clients</h2>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <Slider className='brand-active' {...settings}>
                            <SingleBrandItem img_1="1" />
                            <SingleBrandItem img_1="2" />
                            <SingleBrandItem img_1="3" />
                            <SingleBrandItem img_1="4" />
                            <SingleBrandItem img_1="5" />
                            <SingleBrandItem img_1="6" />
                            <SingleBrandItem img_1="7" />
                            <SingleBrandItem img_1="8" />
                            <SingleBrandItem img_1="9" />
                        </Slider>
                    </div>
                </div>
            </section>

        </>
    );
};

export default ClientSection;