import React from 'react';
import { LuFuel, LuShieldCheck } from "react-icons/lu";
import { IoCarSportOutline } from "react-icons/io5";
import { MdCarRental, MdOutlineCarRepair, MdOutlineCarCrash, MdOutlineNotificationsActive } from "react-icons/md";
import { BiSolidCarBattery, BiSolidCarMechanic } from "react-icons/bi";
import { RiCarWashingLine } from "react-icons/ri";
import { TbTruckDelivery, TbDeviceAnalytics, TbDeviceMobileMessage } from "react-icons/tb";
import { PiFiles } from "react-icons/pi";
import { LiaFileInvoiceDollarSolid } from "react-icons/lia";

const FeaturesSection = () => {
    return (
        <>
            <section className="features__area pt-100 pb-100">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-xl-12">
                            <div className="section__title section__title text-center mb-55">
                                <h2>Key Features</h2>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-xl-12">
                            <div className="features__wrapper">
                                <div className="features__item">
                                    <div className="features__content">
                                        <div className="features__icon">
                                            <i><IoCarSportOutline /></i>
                                        </div>
                                        <div>
                                            <h3>Comprehensive Fleet Management</h3>
                                        </div>
                                    </div>
                                </div>
                                <div className="features__item">
                                    <div className="features__content">
                                        <div className="features__icon">
                                            <i><MdCarRental /></i>
                                        </div>
                                        <div>
                                            <h3>Vehicle Rental Management System</h3>
                                        </div>
                                    </div>
                                </div>
                                <div className="features__item">
                                    <div className="features__content">
                                        <div className="features__icon">
                                            <i><MdOutlineCarRepair /></i>
                                        </div>
                                        <div>
                                            <h3>Garage Management System</h3>
                                        </div>
                                    </div>
                                </div>
                                <div className="features__item">
                                    <div className="features__content">
                                        <div className="features__icon">
                                            <i><BiSolidCarBattery /></i>
                                        </div>
                                        <div>
                                            <h3>Vehicle & Spare Part Buy/Sell Platform</h3>
                                        </div>
                                    </div>
                                </div>
                                <div className="features__item">
                                    <div className="features__content">
                                        <div className="features__icon">
                                            <i><RiCarWashingLine /></i>
                                        </div>
                                        <div>
                                            <h3>Real-time Vehicle Tracking</h3>
                                        </div>
                                    </div>
                                </div>
                                <div className="features__item">
                                    <div className="features__content">
                                        <div className="features__icon">
                                            <i><BiSolidCarMechanic /></i>
                                        </div>
                                        <div>
                                            <h3>Maintenance Scheduling</h3>
                                        </div>
                                    </div>
                                </div>
                                <div className="features__item">
                                    <div className="features__content">
                                        <div className="features__icon">
                                            <i><LuFuel /></i>
                                        </div>
                                        <div>
                                            <h3>Fuel Management</h3>
                                        </div>
                                    </div>
                                </div>
                                <div className="features__item">
                                    <div className="features__content">
                                        <div className="features__icon">
                                            <i><TbTruckDelivery /></i>
                                        </div>
                                        <div>
                                            <h3>Driver Management</h3>
                                        </div>
                                    </div>
                                </div>
                                <div className="features__item">
                                    <div className="features__content">
                                        <div className="features__icon">
                                            <i><TbDeviceAnalytics /></i>
                                        </div>
                                        <div>
                                            <h3>Customizable Reporting & Analytics</h3>
                                        </div>
                                    </div>
                                </div>
                                <div className="features__item">
                                    <div className="features__content">
                                        <div className="features__icon">
                                            <i><MdOutlineCarCrash /></i>
                                        </div>
                                        <div>
                                            <h3>Integrated Reservation System</h3>
                                        </div>
                                    </div>
                                </div>
                                <div className="features__item">
                                    <div className="features__content">
                                        <div className="features__icon">
                                            <i><PiFiles /></i>
                                        </div>
                                        <div>
                                            <h3>Inventory Management for Spare Parts</h3>
                                        </div>
                                    </div>
                                </div>
                                <div className="features__item">
                                    <div className="features__content">
                                        <div className="features__icon">
                                            <i><LiaFileInvoiceDollarSolid /></i>
                                        </div>
                                        <div>
                                            <h3>Automated Billing & Invoicing</h3>
                                        </div>
                                    </div>
                                </div>
                                <div className="features__item">
                                    <div className="features__content">
                                        <div className="features__icon">
                                            <i><MdOutlineNotificationsActive /></i>
                                        </div>
                                        <div>
                                            <h3>Alerts & Notifications</h3>
                                        </div>
                                    </div>
                                </div>
                                <div className="features__item">
                                    <div className="features__content">
                                        <div className="features__icon">
                                            <i><LuShieldCheck /></i>
                                        </div>
                                        <div>
                                            <h3>User Authentication & Access Control</h3>
                                        </div>
                                    </div>
                                </div>
                                <div className="features__item">
                                    <div className="features__content">
                                        <div className="features__icon">
                                            <i><TbDeviceMobileMessage /></i>
                                        </div>
                                        <div>
                                            <h3>Mobile Accessibility</h3>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default FeaturesSection;