import React from 'react';
import { Helmet } from "react-helmet";

const PageHelmet = ({ pageTitle }) => {
    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no" />
                <meta name="robots" content="index, follow" />
                <title>{pageTitle} - VehicleSaas</title>
                <meta name="description" content="Looking for expert vehicle management system development? Find our cutting-edge development solution to optimise your vehicle operations effortlessly." />
            </Helmet>
        </>
    );
};

export default PageHelmet;