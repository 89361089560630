import React from 'react';
import { FcApproval } from "react-icons/fc";

const ContentSection = () => {
    const scrollTo = (id) => {
        const element = document.getElementById(id);
        element.scrollIntoView({
            behavior: "smooth",
        });
    };
    return (
        <>
            <section className="achievement__area sticky">
                <div className="container">
                    <div className="row">
                        <div className="col-xl-12">
                            <div className="scrollLinks">
                                <button onClick={() => scrollTo("enduser")}>End User Features</button>
                                <button onClick={() => scrollTo("admin")}>Admin Side Features</button>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="achievement__area pt-100 pb-100 fixBox" id="enduser">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-xl-12">
                            <div className="section__title section__title-4 text-center mb-55">
                                <h2>End User Features of Fleet Management System</h2>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-xl-12">
                            <div className="achievement__wrapper">
                                <div className="achievement__item">
                                    <i><FcApproval /></i>
                                    <h3>Real-Time Vehicle Tracking</h3>
                                    <ul>
                                        <li><span><b>Live Map View:</b> Access a real-time map displaying the current location of fleet vehicles.</span></li>
                                        <li><span><b>Route History:</b> Review historical routes traveled by individual vehicles.</span></li>
                                    </ul>
                                </div>
                                <div className="achievement__item">
                                    <i><FcApproval /></i>
                                    <h3>Trip Planning & Optimization</h3>
                                    <ul>
                                        <li><span><b>Optimized Routes:</b> Plan and optimize trip routes for efficiency and fuel savings.</span></li>
                                        <li><span><b>Estimated Arrival Times:</b> Receive accurate estimated arrival times for destinations.</span></li>
                                    </ul>
                                </div>
                                <div className="achievement__item">
                                    <i><FcApproval /></i>
                                    <h3>Vehicle Health Monitoring</h3>
                                    <ul>
                                        <li><span><b>Diagnostic Alerts:</b> Receive notifications for vehicle health issues and maintenance needs.</span></li>
                                        <li><span><b>Fuel Efficiency Reports:</b> Access reports on fuel consumption and efficiency.</span></li>
                                    </ul>
                                </div>
                                <div className="achievement__item">
                                    <i><FcApproval /></i>
                                    <h3>Driver Performance Monitoring</h3>
                                    <ul>
                                        <li><span><b>Driver Behavior Analysis:</b> Monitor and analyze driver behavior for safe and efficient driving.</span></li>
                                        <li><span><b>Performance Reports:</b> Receive reports on driver performance metrics.</span></li>
                                    </ul>
                                </div>
                                <div className="achievement__item">
                                    <i><FcApproval /></i>
                                    <h3>Geofencing & Location Alerts</h3>
                                    <ul>
                                        <li><span><b>Geofencing Capability:</b> Set virtual boundaries and receive alerts when vehicles enter or exit defined areas.</span></li>
                                        <li><span><b>Location-Based Notifications:</b> Get notifications for specific events or actions based on location.</span></li>
                                    </ul>
                                </div>
                                <div className="achievement__item">
                                    <i><FcApproval /></i>
                                    <h3>Maintenance Scheduling</h3>
                                    <ul>
                                        <li><span><b>Automated Maintenance Reminders:</b> Receive alerts for scheduled maintenance tasks and inspections.</span></li>
                                        <li><span><b>Service History Access:</b> View the maintenance history of individual vehicles.</span></li>
                                    </ul>
                                </div>
                                <div className="achievement__item">
                                    <i><FcApproval /></i>
                                    <h3>Incident & Emergency Assistance</h3>
                                    <ul>
                                        <li><span><b>Emergency Button:</b> Access an emergency button for immediate assistance in case of accidents or emergencies.</span></li>
                                        <li><span><b>Automated Incident Reporting:</b> Streamline the process of reporting incidents or accidents.</span></li>
                                    </ul>
                                </div>
                                <div className="achievement__item">
                                    <i><FcApproval /></i>
                                    <h3>Document Management</h3>
                                    <ul>
                                        <li><span><b>Digital Document Storage:</b> Store important vehicle-related documents digitally, such as insurance and registration papers.</span></li>
                                        <li><span><b>Expiration Alerts:</b> Receive alerts for document renewals and expirations.</span></li>
                                    </ul>
                                </div>
                                <div className="achievement__item">
                                    <i><FcApproval /></i>
                                    <h3>Customizable Dashboards</h3>
                                    <ul>
                                        <li><span><b>Personalized Views:</b> Customize dashboards to display relevant information for individual users.</span></li>
                                        <li><span><b>Quick Access Widgets:</b> Access key information with widgets for fuel levels, upcoming maintenance, and more.</span></li>
                                    </ul>
                                </div>
                                <div className="achievement__item">
                                    <i><FcApproval /></i>
                                    <h3>Integration with Mobile Apps</h3>
                                    <ul>
                                        <li><span><b>Mobile Accessibility:</b> Access fleet information on the go through dedicated mobile applications.</span></li>
                                        <li><span><b>Push Notifications:</b> Receive push notifications for critical events or updates.</span></li>
                                    </ul>
                                </div>
                                <div className="achievement__item">
                                    <i><FcApproval /></i>
                                    <h3>Fuel Management</h3>
                                    <ul>
                                        <li><span><b>Fuel Consumption Tracking:</b> Monitor fuel consumption for individual vehicles or the entire fleet.</span></li>
                                        <li><span><b>Fuel Price Updates:</b> Receive real-time updates on fuel prices for cost-effective refueling.</span></li>
                                    </ul>
                                </div>
                                <div className="achievement__item">
                                    <i><FcApproval /></i>
                                    <h3>Reporting & Analytics</h3>
                                    <ul>
                                        <li><span><b>Custom Report Generation:</b> Generate custom reports on various aspects of fleet performance.</span></li>
                                        <li><span><b>Analytics Dashboard:</b> Access an analytics dashboard for insights into fleet operations.</span></li>
                                    </ul>
                                </div>
                                <div className="achievement__item">
                                    <i><FcApproval /></i>
                                    <h3>Security Features</h3>
                                    <ul>
                                        <li><span><b>Vehicle Immobilization:</b> Activate remote vehicle immobilization in case of theft or unauthorized use.</span></li>
                                        <li><span><b>Security Alarms:</b> Receive alerts for suspicious activities or unauthorized access.</span></li>
                                    </ul>
                                </div>
                                <div className="achievement__item">
                                    <i><FcApproval /></i>
                                    <h3>User-Friendly Interface</h3>
                                    <ul>
                                        <li><span><b>Intuitive Design:</b> Navigate through the system with an intuitive and user-friendly interface.</span></li>
                                        <li><span><b>Training Resources:</b> Access training materials and resources for effective system use.</span></li>
                                    </ul>
                                </div>
                                <div className="achievement__item">
                                    <i><FcApproval /></i>
                                    <h3>Communication Tools</h3>
                                    <ul>
                                        <li><span><b>Messaging System:</b> Communicate with drivers through an integrated messaging system.</span></li>
                                        <li><span><b>Two-Way Communication:</b> Facilitate two-way communication between dispatchers and drivers.</span></li>
                                    </ul>
                                </div>
                                <div className="achievement__item">
                                    <i><FcApproval /></i>
                                    <h3>Compliance Monitoring</h3>
                                    <ul>
                                        <li><span><b>Regulatory Compliance Reports:</b> Ensure compliance with regulatory requirements through accessible reports.</span></li>
                                        <li><span><b>Automated Compliance Checks:</b> Receive alerts for potential compliance issues.</span></li>
                                    </ul>
                                </div>
                                <div className="achievement__item">
                                    <i><FcApproval /></i>
                                    <h3>Integration with Third-Party Services</h3>
                                    <ul>
                                        <li><span><b>Integration Options:</b> Integrate with third-party services, such as weather updates or traffic information.</span></li>
                                        <li><span><b>API Access:</b> Enable seamless integration with other enterprise systems.</span></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="achievement__area pt-100 pb-100 fixBox grey-bg-12" id="admin">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-xl-12">
                            <div className="section__title section__title-4 text-center mb-55">
                                <h2>Admin Side Features of Fleet Management System</h2>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-xl-12">
                            <div className="achievement__wrapper">
                                <div className="achievement__item">
                                    <i><FcApproval /></i>
                                    <h3>Vehicle Dashboard</h3>
                                    <ul>
                                        <li><span><b>Comprehensive Overview:</b> Access a centralized dashboard displaying real-time information on the entire fleet.</span></li>
                                        <li><span><b>Quick Status Checks:</b> Monitor key metrics such as vehicle location, fuel levels, and maintenance status.</span></li>
                                    </ul>
                                </div>
                                <div className="achievement__item">
                                    <i><FcApproval /></i>
                                    <h3>Fleet Monitoring & Tracking</h3>
                                    <ul>
                                        <li><span><b>Live Map View:</b> Monitor the real-time location of all vehicles on a map.</span></li>
                                        <li><span><b>Historical Route Playback:</b> Review historical routes and activities of individual vehicles.</span></li>
                                    </ul>
                                </div>
                                <div className="achievement__item">
                                    <i><FcApproval /></i>
                                    <h3>Vehicle Health Management</h3>
                                    <ul>
                                        <li><span><b>Diagnostic Alerts:</b> Receive immediate notifications for vehicle health issues and maintenance needs.</span></li>
                                        <li><span><b>Predictive Maintenance:</b> Utilize predictive analytics to schedule maintenance tasks proactively.</span></li>
                                    </ul>
                                </div>
                                <div className="achievement__item">
                                    <i><FcApproval /></i>
                                    <h3>Driver Management</h3>
                                    <ul>
                                        <li><span><b>Driver Profiles:</b> Maintain comprehensive profiles for each driver in the fleet.</span></li>
                                        <li><span><b>Performance Analytics:</b> Analyze driver behavior and performance metrics.</span></li>
                                    </ul>
                                </div>
                                <div className="achievement__item">
                                    <i><FcApproval /></i>
                                    <h3>Geofencing & Alerts</h3>
                                    <ul>
                                        <li><span><b>Geofence Creation:</b> Create virtual boundaries and set up geofences for specific areas.</span></li>
                                        <li><span><b>Alert Configurations:</b> Configure alerts for geofence breaches, speeding, or other predefined events.</span></li>
                                    </ul>
                                </div>
                                <div className="achievement__item">
                                    <i><FcApproval /></i>
                                    <h3>Maintenance Planning & Scheduling</h3>
                                    <ul>
                                        <li><span><b>Automated Maintenance Scheduling:</b> Set up automated schedules for routine maintenance tasks.</span></li>
                                        <li><span><b>Maintenance History Logs:</b> Keep a detailed log of all maintenance activities and repairs.</span></li>
                                    </ul>
                                </div>
                                <div className="achievement__item">
                                    <i><FcApproval /></i>
                                    <h3>Fuel Management</h3>
                                    <ul>
                                        <li><span><b>Fuel Consumption Tracking:</b> Monitor fuel consumption trends and identify opportunities for improvement.</span></li>
                                        <li><span><b>Fuel Efficiency Reports:</b> Generate reports on fuel efficiency and consumption patterns.</span></li>
                                    </ul>
                                </div>
                                <div className="achievement__item">
                                    <i><FcApproval /></i>
                                    <h3>Reporting & Analytics</h3>
                                    <ul>
                                        <li><span><b>Custom Report Generation:</b> Create custom reports based on various parameters.</span></li>
                                        <li><span><b>Performance Analytics:</b> Access analytics tools for insights into fleet performance.</span></li>
                                    </ul>
                                </div>
                                <div className="achievement__item">
                                    <i><FcApproval /></i>
                                    <h3>Security Features</h3>
                                    <ul>
                                        <li><span><b>Vehicle Immobilization:</b> Enable remote vehicle immobilization in case of theft or unauthorized use.</span></li>
                                        <li><span><b>Security Alarms:</b> Receive alerts for suspicious activities or unauthorized access.</span></li>
                                    </ul>
                                </div>
                                <div className="achievement__item">
                                    <i><FcApproval /></i>
                                    <h3>User Management</h3>
                                    <ul>
                                        <li><span><b>Role-Based Access Control:</b> Define roles and permissions for different users within the system.</span></li>
                                        <li><span><b>User Activity Logs:</b> Monitor user activity and system interactions.</span></li>
                                    </ul>
                                </div>
                                <div className="achievement__item">
                                    <i><FcApproval /></i>
                                    <h3>Document Management</h3>
                                    <ul>
                                        <li><span><b>Digital Document Repository:</b> Store and manage digital copies of important documents, including insurance and registration papers.</span></li>
                                        <li><span><b>Document Expiry Alerts:</b> Receive notifications for document renewals and expirations.</span></li>
                                    </ul>
                                </div>
                                <div className="achievement__item">
                                    <i><FcApproval /></i>
                                    <h3>Integration Capabilities</h3>
                                    <ul>
                                        <li><span><b>Third-Party Integrations:</b> Integrate with external systems, such as ERP or accounting software.</span></li>
                                        <li><span><b>API Access:</b> Provide API access for seamless integration with other enterprise applications.</span></li>
                                    </ul>
                                </div>
                                <div className="achievement__item">
                                    <i><FcApproval /></i>
                                    <h3>Alerts & Notifications</h3>
                                    <ul>
                                        <li><span><b>Real-Time Alerts:</b> Receive immediate alerts for critical events or emergencies.</span></li>
                                        <li><span><b>Notification Customization:</b> Customize alert settings based on priority and relevance.</span></li>
                                    </ul>
                                </div>
                                <div className="achievement__item">
                                    <i><FcApproval /></i>
                                    <h3>Communication Tools</h3>
                                    <ul>
                                        <li><span><b>Messaging System:</b> Communicate with drivers and other admin users through an integrated messaging system.</span></li>
                                        <li><span><b>Two-Way Communication:</b> Facilitate efficient communication between dispatchers and drivers.</span></li>
                                    </ul>
                                </div>
                                <div className="achievement__item">
                                    <i><FcApproval /></i>
                                    <h3>Compliance Management</h3>
                                    <ul>
                                        <li><span><b>Regulatory Compliance Reports:</b> Ensure compliance with industry regulations and standards.</span></li>
                                        <li><span><b>Automated Compliance Checks:</b> Receive alerts for potential compliance issues.</span></li>
                                    </ul>
                                </div>
                                <div className="achievement__item">
                                    <i><FcApproval /></i>
                                    <h3>Training & Support</h3>
                                    <ul>
                                        <li><span><b>Training Resources:</b> Provide access to training materials and resources for users.</span></li>
                                        <li><span><b>Customer Support Integration:</b> Integrate customer support features for quick issue resolution.</span></li>
                                    </ul>
                                </div>
                                <div className="achievement__item">
                                    <i><FcApproval /></i>
                                    <h3>Customizable Settings</h3>
                                    <ul>
                                        <li><span><b>Configuration Options:</b> Customize system settings, including units of measurement, alerts, and reporting parameters.</span></li>
                                        <li><span><b>Theme & Branding:</b> Implement branding elements for a personalized user experience.</span></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="services__area pt-100 pb-100">
                <div className="container">
                    <div className="row">
                        <div className="col-xl-12">
                            <div className="section__title section__title-4 text-center mb-55">
                                <h2>Available Platforms</h2>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-xl-4 offset-lg-2">
                            <div className="services__item mb-15 mb-lg-0">
                                <div className="services__icon mb-20">
                                    <img src="assets/img/platform-icon3.jpg" alt="Sjain" />
                                </div>
                                <div className="services__content">
                                    <h3>Web Panel for End User</h3>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-4">
                            <div className="services__item">
                                <div className="services__icon mb-20">
                                    <img src="assets/img/platform-icon4.jpg" alt="Sjain" />
                                </div>
                                <div className="services__content">
                                    <h3>Web Panel for Admin</h3>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default ContentSection;