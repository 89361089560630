import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import ScrollTop from "./components/Helper/ScrollTop";
import BackToTop from "./components/Helper/BackToTop";
import Home from './components/Home/index';
import About from './components/About/index';
import Blog from './components/Blog/index';
import Contact from './components/Contact/index';
import FMS from './components/FMS/index';
import VRMS from './components/VRMS/index';
import VGMS from './components/VGMS/index';
import VSPA from './components/VSPA/index';

function App() {
    return (
        <>
            {/* <Router basename="/name"> */}
            <Router>
                <ScrollTop />
                <Routes>
                    <Route path="/" element={<Home />} />
                    <Route path="/about" element={<About />} />
                    <Route path="/blog" element={<Blog />} />
                    <Route path="/contact" element={<Contact />} />
                    <Route path="/features/fleet-management-system" element={<FMS />} />
                    <Route path="/features/vehicle-rental-management-system" element={<VRMS />} />
                    <Route path="/features/vehicle-garage-management-system" element={<VGMS />} />
                    <Route path="/features/vehicle-spare-part-and-automobile-buy-sell-platform" element={<VSPA />} />
                </Routes>
                <BackToTop />
            </Router>
        </>
    );
}

export default App;
