import React from 'react';

const ProcessSection = () => {
    return (
        <>
            <section className="price__area pt-100 pb-100">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-xl-12">
                            <div className="price__wrapper">
                                <div className="price__item">
                                    <img src="assets/img/home-img1.png" alt="Sjain" />
                                    <h3>Enable online booking and accept payments electronically</h3>
                                    <p>Enhance your market presence and elevate customer satisfaction by streamlining your rental process through our intuitive website plugin.</p>
                                </div>
                                <div className="price__item">
                                    <img src="assets/img/home-img2.png" alt="Sjain" />
                                    <h3>Enhance the Management of Your Fleet</h3>
                                    <p>Eliminate the need for cumbersome spreadsheets or additional administrative hires to boost productivity. Explore a suite of tools designed to effortlessly oversee your fleet's availability, repairs, and finances.</p>
                                </div>
                                <div className="price__item">
                                    <img src="assets/img/home-img3.png" alt="Sjain" />
                                    <h3>Transition to a Paper-Free Environment</h3>
                                    <p>We create a customizable rental agreement tailored to your needs. During the customer checkout process, you can utilize our mobile app to upload vehicle photos and have the customer digitally sign the agreement.</p>
                                </div>
                                <div className="price__item">
                                    <img src="assets/img/home-img4.png" alt="Sjain" />
                                    <h3>Advanced Reporting</h3>
                                    <p>Monitor your key performance indicators through a personalized and user-friendly dashboard, and easily export all the necessary information.</p>
                                </div>
                                <div className="price__item">
                                    <img src="assets/img/home-img5.png" alt="Sjain" />
                                    <h3>Integrated Payments</h3>
                                    <p>Automatically send your customers customized receipts and booking confirmations.</p>
                                </div>
                                <div className="price__item">
                                    <img src="assets/img/home-img6.png" alt="Sjain" />
                                    <h3>Cloud-Based Solution</h3>
                                    <p>Initiate your session from any location without the need for downloads or installations using our cloud-based software.</p>
                                </div>
                                <div className="price__item">
                                    <img src="assets/img/home-img7.png" alt="Sjain" />
                                    <h3>Mobile Access</h3>
                                    <p>Install our mobile application on your Android or iOS device to efficiently manage your business while on the move.</p>
                                </div>
                                <div className="price__item">
                                    <img src="assets/img/home-img8.png" alt="Sjain" />
                                    <h3>Manage from Anywhere</h3>
                                    <p>Efficiently manage tasks related to your fleet and access crucial data seamlessly, no matter where you are, using user-friendly web and smartphone applications designed for busy and dispersed fleets.</p>
                                </div>
                                <div className="price__item">
                                    <img src="assets/img/home-img9.png" alt="Sjain" />
                                    <h3>Collaborate as a Team</h3>
                                    <p>Engage in active collaboration with your entire team on fleet operations, utilizing flexible permissions for unlimited users, and features centered around exception management.</p>
                                </div>
                                <div className="price__item">
                                    <img src="assets/img/home-img10.png" alt="Sjain" />
                                    <h3>Implement Process Automation</h3>
                                    <p>Streamline your maintenance process comprehensively with robust operational workflows and data integrations, eliminating the need for manual data entry.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default ProcessSection;